<template>
    <v-dialog
        v-model="showModal"
        :max-width="maxWidth"
        @click:outside="closeModal"
    >
        <v-card>
            <v-card-title class="text-subtitle-1 py-5 px-5 modal--border d-flex justify-space-between align-center">
                <span>
                    <slot name="title" />
                </span>
                <v-hover
                    v-slot="{ hover }"
                    style="cursor: pointer;"
                >
                    <v-icon
                        :color="hover ? '#000' : 'gray'"
                        @click="closeModal"
                    >
                        mdi-close
                    </v-icon>
                </v-hover>
            </v-card-title>

            <v-card-text :class="`pt-5 pb-0 px-5 ${cardBodyClass}`">
                <slot name="body" />
            </v-card-text>

            <slot
                v-if="isShowBtns"
                name="actions"
            >
                <v-card-actions
                    class="py-5 px-5"
                >
                    <v-btn
                        color="accent"
                        class="py-4 px-3 mr-4"
                        height="max-content"
                        :loading="loading"
                        :disabled="!isActiveConfirm"
                        @click="confirmModal"
                    >
                        confirm
                    </v-btn>

                    <v-btn
                        class="py-4 px-3"
                        height="max-content"
                        @click="closeModal"
                    >
                        cancel
                    </v-btn>
                </v-card-actions>
            </slot>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: {
        showModal: {
            type: Boolean,
            required: true
        },
        maxWidth: {
            type: Number,
            default: 600
        },
        isShowBtns: {
            type: Boolean,
            require: false,
            default: true
        },
        isActiveConfirm: {
            type: Boolean,
            require: false,
            default: true
        },
        cardBodyClass: {
            type: String,
            required: false
        },
        loading: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        closeModal() {
            this.$emit('closeModal', true)
        },
        confirmModal() {
            this.$emit('confirmModal', true)
        }
    }
}
</script>

<style lang="scss">
    .modal--border {
        border-top: 3px solid #82b1ff;
        border-bottom: 1px solid #D3D9E2
    }
    .v-dialog {
        .v-alert{
            .v-icon{
                color: #82b1ff;
            }
        }
    }
</style>
