<template>
    <modal
        :show-modal="showModal"
        @closeModal="onCloseModal"
        @confirmModal="onConfirmModal"
    >
        <template #title>
            <span>Create promocode</span>
        </template>
        <template #body>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-text-field
                    v-model="discount_code"
                    outlined
                    color="accent"
                    class="my-3"
                    :label="'Discount code'"
                    hide-details
                    :rules="valid_discount_code"
                    required
                />
                <v-text-field
                    v-model="discount_rate"
                    outlined
                    color="accent"
                    class="my-3"
                    :label="'Discount rate'"
                    hide-details
                    :rules="valid_discount_rate"
                    required
                />
                <v-select
                    v-model="domain"
                    :items="getterDomains"
                    label="Domain"
                    class="my-3"
                    outlined
                    :rules="valid_domain"
                    required
                    hide-details
                />
                <!-- // DATE TIME -->
                <v-datetime-picker
                    v-model="ts_expired"
                    label="ts_expired"
                    class="my-3"
                    :text-field-props="{
                        outlined: true
                    }"
                    :date-picker-props="{
                        min:minDateTime
                    }"
                    time-format="h:mm"
                    date-format="MMM dd,"
                    required
                    :rules="valid_ts_expired"
                >
                    <template #dateIcon>
                        Date
                    </template>
                    <template #timeIcon>
                        Time
                    </template>
                </v-datetime-picker>
            </v-form>
        </template>
    </modal>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import Modal from '@/components/shared/Modals/Modal'
import {
    ACTION_GET_DOMAINS
} from '@/store/modules/config/action-types';

const {
    mapActions: mapAdminActions
} = createNamespacedHelpers('config');
export default {
    components: {
        Modal
    },
    data() {
        return {
            valid: false,
            showModal: true,
            discount_code: '',
            valid_discount_code: [
                (v) => !!v || 'Discount code is required'
            ],
            discount_rate: '',
            valid_discount_rate: [
                (v) => ((v > 0 && v < 100) && !!v) || 'Discount rate is required'
            ],
            domain: '',
            valid_domain: [
                (v) => !!v || 'Domain code is required'
            ],
            ts_expired: '',
            valid_ts_expired: [
                (v) => !!v || 'ts_expired code is required'
            ]
        }
    },
    computed: {
        ...mapGetters('config', [
            'getterDomains'
        ]),
        minDateTime() {
            return new Date().toISOString()
        }
    },
    async created() {
        await this[ACTION_GET_DOMAINS]()
    },
    methods: {
        ...mapAdminActions({
            ACTION_GET_DOMAINS
        }),
        onCloseModal() {
            this.showModal = false
            this.$emit('closeModal', true)
        },
        onConfirmModal() {
            this.$refs.form.validate()
            this.$nextTick(() => {
                if (this.valid) {
                    this.$emit('confirmModal', {
                        discount_code: this.discount_code,
                        discount_rate: this.discount_rate,
                        domain: this.domain,
                        expiry_date: this.ts_expired
                    })
                }
            })
        }
    }
}
</script>

<style>

</style>
