<template>
    <div class="text-center">
        <v-menu
            class="z-99"
            bottom
            offset-y
            @input="hoverRow"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    v-if="!color"
                    icon
                    v-bind="attrs"
                    class="font-weight-bold"
                    v-on="on"
                >
                    <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
                <v-icon
                    v-else
                    v-bind="attrs"
                    :color="color"
                    v-on="on"
                >
                    mdi-dots-vertical
                </v-icon>
            </template>
            <v-list
                dark
                dense
            >
                <v-list-item-group
                    v-model="selectedItem"
                    color="primary"
                    active-class=""
                >
                    <v-list-item
                        v-for="(toolbar, i) in toolbars"
                        :key="i"
                        @click="action({modal: toolbar.modal, actionName: toolbar.name})"
                    >
                        <v-list-item-icon>
                            <v-icon v-text="toolbar.icon" />
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title v-text="toolbar.text" />
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>

<script>

export default {
    props: {
        toolbars: {
            type: Array,
            required: true
        },
        item: {
            type: Object,
            required: false
        },
        listType: {
            type: String,
            required: false
        },
        color: {
            type: String
        }
    },
    data() {
        return {
            selectedItem: null
        }
    },
    methods: {
        action(payload) {
            if (this.$route.name === 'hr-clients-details' && this.listType !== 'orders') {
                this.$emit('menuActivated', payload)
                return
            }
            this.$emit('menuActivated', this.item, payload)
            this.$nextTick(() => {
                this.selectedItem = null
            })
        },
        hoverRow(val) {
            const rowElement = this.$el.closest('.hovered-row');
            if (rowElement) {
                if (val) {
                    rowElement.classList.add('table-row--selected-hover')
                } else {
                    rowElement.classList.remove('table-row--selected-hover')
                }
            }
            this.$emit('hoverRow', val)
        }
    }
}
</script>

<style lang="scss">
    .z-99{
        z-index: 999;
        .v-btn.v-btn--active{
            color: #fff !important;
        }
    }
    .table-row--selected-hover {
        background-color: rgba(130, 178, 255, 0.5) !important;
    }
</style>
