<template>
    <div class="wrapper py-5">
        <v-container
            fluid
            class="white rounded"
        >
            <v-row class="mb-5" />

            <the-main-filter
                v-if="search_fields"
                :search-fields="search_fields"
                :search-values="searchOptions"
                :active-preset="searchOptions.preset"
                :presets="getterPresets"
                @filterTable="onFilterTable"
            >
                <template
                    slot="left_panel"
                    slot-scope="{ events: { updateShortcats } }"
                >
                    <v-btn
                        color="success"
                        class="promocodes-table__create mr-3"
                        @click.stop="onCreateNewPromocode"
                    >
                        Create
                    </v-btn>
                    <the-filter-preset
                        :active-preset="searchOptions.preset"
                        :presets="getterPresets"
                        @update:shortcat="updateShortcats"
                    />
                </template>
            </the-main-filter>

            <v-data-table
                :headers="tableHeader"
                :items="getterPromoList"
                item-key="id"
                :server-items-length="getterTotal"
                :loading="getterListLoading"
                hide-default-footer
                calculate-widths
                class="mt-10"
                must-sort
                :sort-by="sortBy"
                :sort-desc="sortDesc"
                @update:options="onSortTable"
            >
                <template #header="{ }">
                    <thead
                        v-if="!mobile"
                        class="v-data-table-header"
                    >
                        <tr>
                            <th
                                v-for="h in tableHeader"
                                :key="h.value"
                            >
                                <template v-if="h.value === 'discount_code'">
                                    <div class="d-flex align-center">
                                        <div class="mr-4">
                                            <p class="mb-0 grey--text">
                                                Count:
                                            </p>
                                            <p class="mb-0">
                                                <v-progress-circular
                                                    v-if="getterTotalsLoading"
                                                    ref="loader"
                                                    :size="14"
                                                    color="primary"
                                                    indeterminate
                                                />
                                                <b v-else>{{ getterSummary.count | number }}</b>
                                            </p>
                                        </div>
                                    </div>
                                </template>
                            </th>
                        </tr>
                    </thead>
                    <thead v-else>
                        <tr>
                            <th class="d-flex">
                                <div class="d-flex align-center">
                                    <div class="mr-4">
                                        <p class="mb-0 grey--text">
                                            Count:
                                        </p>
                                        <p class="mb-0">
                                            <v-progress-circular
                                                v-if="getterTotalsLoading"
                                                ref="loader"
                                                :size="14"
                                                color="primary"
                                                indeterminate
                                            />
                                            <b v-else>{{ getterSummary.count | number }}</b>
                                        </p>
                                    </div>
                                </div>
                            </th>
                        </tr>
                    </thead>
                </template>
                <template v-slot:body="{ items }">
                    <table-skeleton
                        v-if="getterListLoading && items.length === 0"
                        :per-page="searchOptions.per_page"
                        :column-count="tableHeaderLength"
                    />
                    <table-loader
                        v-if="getterListLoading"
                    />
                    <tbody>
                        <tr
                            v-for="item in items"
                            :key="item.id"
                            class="hovered-row"
                        >
                            <!-- created_at -->
                            <td>
                                <div class="d-flex align-center">
                                    <div>{{ $date.fromNowFullText(item.created_at) }}</div>
                                    <information-tooltip>
                                        <span>{{ $date.shortText(item.created_at) }}</span>
                                    </information-tooltip>
                                </div>
                            </td>
                            <!-- discount code -->
                            <td>
                                <p class="font-weight-bold">
                                    {{ item.discount_code }}
                                </p>
                            </td>
                            <!-- rate -->
                            <td>
                                <p class="font-weight-bold">
                                    {{ item.discount_rate | percent }}
                                </p>
                            </td>
                            <!-- domain -->
                            <td>
                                <domain-favicon
                                    :domain="item.domain"
                                />
                            </td>

                            <!-- expires_at -->
                            <td>
                                <div class="d-flex align-center">
                                    <div
                                        :class="`${$date.dateColorDue(item.expires_at)}--text`"
                                    >
                                        {{ $date.fromNowFullText(item.expires_at) }}
                                    </div>
                                    <information-tooltip>
                                        <span>{{ $date.shortText(item.expires_at) }}</span>
                                    </information-tooltip>
                                </div>
                            </td>

                            <!-- issued_by -->
                            <td>
                                <div
                                    v-if="item.author && item.author.id"
                                    class="d-flex align-center"
                                >
                                    <avatar-rolles
                                        :access-status="'writer'"
                                        :firstname="item.author.firstname"
                                        :lastname="item.author.lastname"
                                        :avatar-url="item.author.avatar_url"
                                        :avatar-fallback="item.author.avatar_fallback"
                                        avatar-size="43"
                                    />
                                </div>
                                <span
                                    v-else
                                    class="font-weight-bold"
                                >
                                    —
                                </span>
                            </td>
                            <td>
                                <div>
                                    <span
                                        v-if="item.author && item.author.firstname"
                                        class="font-weight-bold"
                                    >
                                        {{ item.author.firstname }}
                                    </span>
                                    <span
                                        v-if="item.author && item.author.lastname"
                                        class="font-weight-bold"
                                    >
                                        {{ item.author.lastname }}
                                    </span>
                                    <p v-if="item.author">
                                        Admin: {{ item.author.id }}
                                    </p>
                                </div>
                            </td>
                            <!-- orders count -->
                            <td>
                                <v-avatar
                                    size="35"
                                    color="light-blue lighten-5"
                                >
                                    <span
                                        style="color: #1976d2"
                                        class="font-weight-bold"
                                    >
                                        {{ item.counters.orders_count }}
                                    </span>
                                </v-avatar>
                            </td>
                            <!-- Status -->
                            <td>
                                <img
                                    v-if="item.status_active"
                                    src="@images/svg/trusted_writer.svg"
                                    alt="trusted_writer"
                                    width="21"
                                    height="21"
                                    class="mt-1"
                                >
                                <img
                                    v-else
                                    src="@images/svg/new_writer.svg"
                                    alt="trusted_writer"
                                    width="21"
                                    height="21"
                                    class="mt-1"
                                >
                            </td>
                            <td>
                                <table-rows-menu
                                    :toolbars="toolbars"
                                    :item="item"
                                    class="mt-2"
                                    @menuActivated="onMenuActivated"
                                />
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>

            <div
                v-if="!getterPromoList.length && !getterListLoading "
                class="d-flex flex-column align-center w-full mt-5 mb-5"
            >
                <div class="">
                    <img
                        src="@images/svg/no-data.svg"
                        alt=""
                    >
                </div>
                <p class="text-h6">
                    No codes found
                </p>
            </div>

            <pagination-with-perpage
                v-if="!getterTotalsLoading"
                :list-lenght="getterPromoList.length"
                :last-page="getterLastPage"
                :search-options="searchOptions"
                :total-visible="7"
                @updatePage="paginationPageEvent"
                @updatePerpage="onFilterTable"
            />

            <component
                :is="modalComponent"
                :title="modal.title"
                :description="modal.description"
                :input-label="modal.inputLabel"
                @closeModal="onCloseModal"
                @confirmModal="onConfirmModal"
            />
        </v-container>
    </div>
</template>

<script>
import { createNamespacedHelpers, mapGetters } from 'vuex';
import { eventBus } from '@/helpers/event-bus/'
import filtersMixin from '@/mixins/filtersMixin'
import {
    TABLE_HEADER, TOOLBARS
} from '@/constants/Promocodes/index.js'

import {
    ACTION_ACTIVATE_PROMO,
    ACTION_CREATE_PROMO,
    ACTION_DEACTIVATE_PROMO,
    ACTION_GET_PROMO_LIST,
    ACTION_GET_PRESETS,
    ACTION_GET_PROMO_TOTALS
} from '@/store/modules/promo/action-types';

import {
    CLEAR_PROMO_LIST
} from '@/store/modules/promo/mutation-types';

import PaginationWithPerpage from '@/components/shared/Tables/PaginationWithPerpage';
import TableSkeleton from '@/components/shared/Tables/TableSkeleton';
import DomainFavicon from '@/components/shared/Helpers/DomainFavicon'
import CreatePromo from '@/components/shared/Modals/CreatePromo'
import TheMainFilter from '@/components/shared/TableFilters/TheMainFilter'
import AvatarRolles from '@/components/shared/Avatars/AvatarRolles'
import TableRowsMenu from '@/components/shared/Tables/TableRowsMenu'
import ModalAlert from '@/components/shared/Modals/ModalAlert'
import TheFilterPreset from '@/components/shared/TableFilters/TheFilterPreset'
import TableLoader from '@/components/shared/Tables/TableLoader.vue';
import InformationTooltip from '@/components/shared/InformationTooltip'

import {
    searchFieldsHelper
} from '@/services/searchFieldsHelpers'

const {
    mapMutations
} = createNamespacedHelpers('promo');

const {
    mapActions
} = createNamespacedHelpers('promo');

export default {
    components: {
        PaginationWithPerpage,
        TableSkeleton,
        DomainFavicon,
        CreatePromo,
        TheMainFilter,
        AvatarRolles,
        TableRowsMenu,
        ModalAlert,
        TheFilterPreset,
        TableLoader,
        InformationTooltip
    },
    mixins: [
        filtersMixin
    ],
    metaInfo: {
        title: 'Promocodes'
    },
    data() {
        return {
            loading: true,
            searchOptions: {
                page: 1,
                per_page: 10,
                search_for: '',
                search_by: '',
                sort_by: 'created_at',
                sort_order: 'DESC',
                from: '',
                to: '',
                preset: ''
            },
            sort: {
                sort_by: 'discount_rate',
                sort_order: 'DESC'
            },
            search_fields: null,
            hoveredItem: {},
            showModal: false,
            isFirstLoad: true,
            savedScroll: { x: 0, y: 0 },
            modal: {},
            mobile: false
        }
    },
    computed: {
        ...mapGetters('promo', [
            'getterPromoList',
            'getterTotal',
            'getterCurrentPage',
            'getterLastPage',
            'getterPresets',
            'getterTotalsLoading',
            'getterListLoading',
            'getterSummary'
        ]),
        tableHeader() {
            return TABLE_HEADER
        },
        tableHeaderLength() {
            return TABLE_HEADER.length
        },
        modalComponent() {
            return this.modal?.name || ''
        },
        queryObject() {
            const { query } = this.$route

            return {
                ...query,
                page: +this.$route.query.page || 1,
                per_page: +this.$route.query.per_page || 10
            }
        },
        sortBy() {
            return this.$route.query.sort_by || 'created_at'
        },
        sortDesc() {
            return this.$route.query.sort_order === 'DESC'
        },
        toolbars() {
            return TOOLBARS
        }
    },
    async activated() {
        this.$nextTick(() => {
            window.scroll(0, this.savedScroll.y);
        })
        this.searchOptions = {
            ...this.searchOptions,
            ...this.queryObject
        }
        const requests = [this.get_search_fields()]
        if (!this.isFirstLoad) {
            requests.push(this.getPromoList())
        }
        await Promise.allSettled(requests)
    },
    beforeRouteLeave(to, from, next) {
        const scrollOffset = { y: window.pageYOffset || document.documentElement.scrollTop, x: 0 }
        this.savedScroll = { ...scrollOffset }
        if (!to.name.includes('promo')) {
            this[CLEAR_PROMO_LIST]()
        }
        next()
    },
    created() {
        if (window.innerWidth <= 599) this.mobile = true
    },
    methods: {
        async get_search_fields() {
            this.search_fields = await searchFieldsHelper.getSearchField('promo')
        },
        ...mapActions({
            ACTION_GET_PROMO_LIST,
            ACTION_CREATE_PROMO,
            ACTION_ACTIVATE_PROMO,
            ACTION_DEACTIVATE_PROMO,
            ACTION_GET_PRESETS,
            ACTION_GET_PROMO_TOTALS
        }),
        ...mapMutations({
            CLEAR_PROMO_LIST
        }),
        openFullRow(id) {
            if (this.openedRow.includes(id)) {
                this.openedRow = this.openedRow.filter((item) => item !== id)
            } else {
                this.openedRow.push(id)
            }
        },
        // TABLE LOGIC
        async getPromoList(with_total = true) {
            try {
                if (JSON.stringify(this.searchOptions) !== JSON.stringify(this.queryObject)) {
                    this.$router.replace({ query: { ...this.searchOptions } })
                }
                const requestArr = [this[ACTION_GET_PROMO_LIST]({ ...this.searchOptions, mode: 'list' }), this[ACTION_GET_PRESETS]()]
                if (with_total) {
                    requestArr.push(this[ACTION_GET_PROMO_TOTALS]({ ...this.searchOptions, mode: 'count' }))
                }
                await Promise.all(requestArr)
                this.isFirstLoad = false
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        paginationPageEvent(e) {
            this.searchOptions.page = e
            this.getPromoList(false)
        },
        async onFilterTable(payload) {
            if (this.isFirstLoad) {
                this.searchOptions = { ...this.searchOptions, ...payload }
            } else {
                this.searchOptions = { ...this.searchOptions, ...payload, page: 1 }
            }
            await this.getPromoList()
            this.isFirstLoad = false
        },
        async onSortTable(e) {
            // eslint-disable-next-line prefer-destructuring
            if (!this.isFirstLoad) {
                this.sort.sort_by = e.sortBy[0] || 'ts_issued'
                this.sort.sort_order = e.sortDesc[0] ? 'DESC' : 'ASC'
                this.searchOptions = {
                    ...this.searchOptions,
                    ...this.sort,
                    page: 1
                }
                await this.getPromoList()
            }
        },
        async onConfirmModal(payload) {
            try {
                switch (this.actionName) {
                case 'activate':
                    await this[ACTION_ACTIVATE_PROMO]({ discount_code: this.currentItem.discount_code })
                    break
                case 'deactivate':
                    await this[ACTION_DEACTIVATE_PROMO]({ discount_code: this.currentItem.discount_code })
                    break
                case 'new_promo':
                    await this[ACTION_CREATE_PROMO](payload)
                    await this.getPromoList()
                    break
                default:
                    return
                }
                eventBus.$emit('showSnackBar', this.modal.success_message, 'success')
                this.onCloseModal()
            } catch (error) {
                eventBus.$emit('showSnackBar', error, 'error')
            }
        },
        onCreateNewPromocode() {
            this.actionName = 'new_promo'
            this.modal = {
                name: 'CreatePromo',
                success_message: 'Promocode was created'
            }
        },
        // MODALS ACTION
        onMenuActivated(item, action) {
            this.actionName = action.actionName
            this.modal = action.modal
            this.currentItem = item
        },
        onCloseModal() {
            this.modal = {}
            this.currentItem = {}
            this.actionName = ''
        }
    }
}
</script>

<style lang="scss">
@media (max-width: 599px) {
    .promocodes-table__create {
        margin-bottom: 10px;
    }
}
</style>
