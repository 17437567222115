export const TABLE_HEADER = [
    {
        text: 'Сreated at',
        value: 'created_at',
        sortable: true,
        width: '130px'
    },
    {
        text: 'Discount code',
        value: 'discount_code',
        sortable: true
    },
    {
        text: 'Discount Rate',
        value: 'discount_rate',
        sortable: true,
        width: '130px'
    },
    {
        text: 'Domain',
        value: 'domain',
        sortable: true,
        width: '90px'
    },
    {
        text: 'Expired at',
        value: 'expires_at',
        sortable: true,
        width: '130px'
    },
    {
        text: '',
        value: 'avatar',
        sortable: false,
        width: '80px'
    },
    {
        text: 'Author',
        value: 'author',
        sortable: false,
        width: '200px'
    },
    {
        text: 'Orders count',
        value: 'orders_count',
        sortable: false,
        width: '130px'
    },
    {
        text: 'Status',
        value: 'status_active',
        sortable: false,
        width: '80px'
    },
    {
        text: '',
        sortable: false,
        width: '55px'
    }
]

export const TOOLBARS = [
    {
        name: 'activate',
        text: 'Activate',
        modal: {
            name: 'ModalAlert',
            title: 'Activate the promocode',
            description: 'Do you really want to activate selected promocode?',
            success_message: 'The promocode is activated'
        },
        icon: 'mdi-checkbox-marked-circle-outline'
    },
    {
        name: 'deactivate',
        text: 'Deactivate',
        modal: {
            name: 'ModalAlert',
            title: 'Promocode deactivation',
            description: 'Do you want to deactivate selected promocode?',
            success_message: 'The promocode is deactivated'
        },
        icon: 'mdi-close-circle-outline'
    }
]
